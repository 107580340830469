.jd-sticky-nav-bar {
    background-color: gray;
    padding: 1px;
    width: -moz-fit-content;
    z-index: 1;
}

.nav-link {
    color: skyblue;
}

a {
    cursor: pointer;
}