html,
body, svg {
    width: 100%;
    height: 100%;
    padding: 0px;
    margin: 0px;
}

.fill-window {
        /*background-color: skyblue;*/
    height: 100vh;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    overflow: -moz-hidden-unscrollable;
}

.button {
    border: 2px solid #fff;
    box-sizing: inherit;
    cursor: pointer;
    display: inline-block;
    font-size: 13pt;
    padding: 10px 20px;
    transition: all 0.5s;
}

.flex {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

h1 {
    font-weight: normal;
}
* {
    box-sizing: border-box;
}

/*[data-reactroot]*/
/*    {height: 100% !important; }*/

/*html, body, #app, #app>div {*/
/*    height: 100%*/
/*}*/